<template>
  <div>
    <KTCodePreview v-bind:title="'Thêm mới nhà cung cấp'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Thông tin nhà cung cấp
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Thông tin cá nhân
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Thông tin ngân hàng
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Thông tin doanh nghiệp
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group class="required-control">
                  <label> Tên nhà cung cấp: </label>
                  <b-form-input
                    tabindex="1"
                    v-model="$v.name.$model"
                    :state="validateState('name')"
                    type="text"
                    size="sm"
                    placeholder="Nhập tên nhà cung cấp"
                    aria-describedby="input-name-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.name.required"
                    id="input-name-live-feedback"
                    >Vui lòng nhập tên nhà cung cấp!</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="!$v.name.minLength"
                    id="input-name-live-feedback"
                    >Nhập tên với ít nhất 3 kí tự!</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>CMND:</label>
                  <b-form-input
                    tabindex="6"
                    v-model="identityCard"
                    type="text"
                    size="sm"
                    placeholder="Nhập CMND"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-bank">Ngân hàng:</label>
                  <b-form-input
                    tabindex="8"
                    v-model="bank"
                    type="text"
                    size="sm"
                    placeholder="Nhập ngân hàng"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Tên người đại diện:</label>
                  <b-form-input
                    tabindex="12"
                    v-model="representative"
                    type="text"
                    size="sm"
                    placeholder="Nhập tên người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label class="required-control"> Số điện thoại: </label>
                  <b-form-input
                    tabindex="2"
                    v-model="$v.phoneNo.$model"
                    :state="validateState('phoneNo')"
                    type="number"
                    size="sm"
                    placeholder="Nhập số điện thoại"
                    aria-describedby="input-mobile-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.phoneNo.required"
                    id="input-mobile-live-feedback"
                    >Vui lòng nhập số điện thoại</b-form-invalid-feedback
                  >

                  <b-form-invalid-feedback
                    v-if="!$v.phoneNo.isValidPhoneNumber"
                    id="input-mobile-live-feedback"
                    >Số điện thoại chưa hợp lệ</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    tabindex="7"
                    v-model="address"
                    type="text"
                    size="sm"
                    placeholder="Nhập địa chỉ"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-branch">Chi nhánh:</label>
                  <b-form-input
                    tabindex="9"
                    v-model="bankBranch"
                    type="text"
                    size="sm"
                    placeholder="Nhập chi nhánh"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-repPosition">Vị trí người đại diện:</label>
                  <b-form-input
                    tabindex="13"
                    v-model="repPosition"
                    type="text"
                    size="sm"
                    placeholder="Nhập vị trí người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>Email:</label>
                  <b-form-input
                    tabindex="3"
                    v-model="$v.email.$model"
                    :state="validateState('email')"
                    type="email"
                    size="sm"
                    placeholder="Nhập email"
                    aria-describedby="input-email-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.email.email"
                    id="input-email-live-feedback"
                    >Vui lòng nhập email hợp lệ!</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-account-no">Số tài khoản:</label>
                  <b-form-input
                    tabindex="10"
                    v-model="bankAccountNo"
                    type="text"
                    size="sm"
                    placeholder="Nhập số tài khoản"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="input-group-repPhoneNo">
                  <label for="input-repPhoneNo"
                    >Điện thoại người đại diện:</label
                  >
                  <b-form-input
                    tabindex="14"
                    v-model="repPhoneNo"
                    type="text"
                    size="sm"
                    placeholder="Nhập điện thoại người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>Mã số thuế:</label>
                  <b-form-input
                    tabindex="4"
                    v-model="taxNo"
                    type="text"
                    size="sm"
                    placeholder="Nhập mã số thuế"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-account-name">Chủ tài khoản:</label>
                  <b-form-input
                    tabindex="11"
                    v-model="bankAccountName"
                    type="text"
                    size="sm"
                    placeholder="Nhập chủ tài khoản"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label for="input-type">Loại:</label>
                  <b-form-select
                    tabindex="5"
                    v-model="selectedType"
                    :options="typeOptions"
                    style="marginleft: auto"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3"> </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Số ngày thanh toán:</label>
                  <b-form-input
                    v-model="numPaymentDate"
                    type="text"
                    size="sm"
                    placeholder="Nhập số ngày thanh toán"
                    class="text-right"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>

      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-col>
              <b-button
                tabindex="15"
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="onSubmit"
                >Lưu</b-button
              >
              <!-- </b-col> -->
              <!-- <b-col lg="2" class="pb-2"> -->
              <router-link to="/providers" tag="button">
                <b-button
                  tabindex="16"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  >Hủy</b-button
                >
              </router-link>
            </b-col>
          </b-row>

          <!-- </b-col> -->
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { v4 as uuidv4 } from 'uuid';
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { isValidPhoneNumber } from './../../../utils/validation';

export default {
  mixins: [validationMixin],
  data() {
    return {
      id: uuidv4(),
      name: '',
      phoneNo: '',
      email: '',
      taxNo: '',
      identityCard: '',
      address: '',
      bank: '',
      bankBranch: '',
      bankAccountNo: '',
      bankAccountName: '',
      representative: '',
      repPosition: '',
      repPhoneNo: '',
      status: 1,
      textInfo: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '15px',
      },
      selectedType: null,
      typeOptions: [
        { value: null, text: 'Hãy chọn loại' },
        { value: '1', text: 'Cá nhân' },
        { value: '2', text: 'Doanh nghiệp' },
      ],
      numPaymentDate: 0,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhà cung cấp', route: '/providers' },
      { title: 'Danh sách nhà cung cấp', route: '/providers' },
      { title: 'Thêm mới nhà cung cấp' },
    ]);
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit: async function () {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const data = {
        id: this.id,
        name: this.$v.name.$model,
        phoneNo: this.$v.phoneNo.$model,
        email: this.$v.email.$model,
        taxNo: this.taxNo,
        identityCard: this.identityCard,
        address: this.address,
        bank: this.bank,
        bankBranch: this.bankBranch,
        bankAccountNo: this.bankAccountNo,
        bankAccountName: this.bankAccountName,
        representative: this.representative,
        repPosition: this.repPosition,
        repPhoneNo: this.repPhoneNo,
        status: this.status,
        type: this.selectedType,
        numPaymentDate: this.numPaymentDate,
      };

      ApiService.setHeader();
      ApiService.post('providers', data).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.$router.push({
            name: 'list-provider',
            params: { createProviderSuccess: true },
          });
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: { email },
    phoneNo: {
      required,
      isValidPhoneNumber,
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
